import * as React from "react";
import { Container, Row, Col, Card } from "react-bootstrap";

import { CjsJumbotron, Stack, QuoteForm, PageHelmet } from "components";

import "./contact.scss";

const Contact = () => {
  return (
    <Container fluid>
      <PageHelmet
        title="Contact Us - CJ Shaughnessy Crane"
        description="Reach out to us to get a free quote or fill out a quote form and well contact you!"
      />
      <CjsJumbotron
        header="CONTACT US"
        showButton={false}
        message=""
        size="equipment"
      />
      <Container className="page-container">
        <Row>
          <Col>
            <Row>
              <Col xs={12} md={7}>
                <h3
                  className="text-primary"
                  style={{ fontWeight: "bold", marginBottom: "0" }}
                >
                  REACH OUT
                </h3>
                <p className="sub-header">phone - email - quote</p>
                <p className="description-info">
                  We'd love to hear from you - you can reach us directly or fill
                  out the form below to get a free quote!
                </p>
              </Col>
            </Row>
            <Stack
              direction="column"
              space={100}
              sx={{ marginBottom: "100px", marginTop: "30px" }}
            >
              <Row>
                <Col xs={12} lg={6} className="contact-card-col">
                  <Card className="contact-card">
                    <Card.Body>
                      <h4>Our Locations</h4>
                      <Stack
                        direction="column"
                        space={20}
                        sx={{ marginTop: "20px", marginBottom: "20px" }}
                      >
                        <Stack direction="column" space={5}>
                          <div>
                            Shop and Main Yard -{" "}
                            <a
                              className="address"
                              href="https://maps.google.com/?q=520 Bodwell St, Avon, MA 02322"
                            >
                              520 Bodwell St, Avon, MA 02322
                            </a>
                          </div>
                          <div>
                            Main Office & Warehouse -{" "}
                            <a
                              className="address"
                              href="https://maps.google.com/?q=500 Bodwell St, Avon, MA 02322"
                            >
                              500 Bodwell St, Avon, MA 02322
                            </a>
                          </div>
                          <div>
                            Warehouse -{" "}
                            <a
                              className="address"
                              href="https://maps.google.com/?q=108 Bodwell St, Avon, MA 02322"
                            >
                              108 Bodwell St, Avon, MA 02322
                            </a>
                          </div>
                          {/* <div>
                            Storage Yard -{" "}
                            <a
                              className="address"
                              href="https://maps.google.com/?q=270 E High St, Avon, MA 02322"
                            >
                              270 East High St, Avon, MA 02322
                            </a>
                          </div>
                          <div>
                            Storage Yard -{" "}
                            <a
                              className="address"
                              href="https://maps.google.com/?q=45 Maple St, Stoughton, MA 02072"
                            >
                              45 Maple St, Stoughton, MA 02072
                            </a>
                          </div> */}
                        </Stack>

                        <Stack direction="column" space={5}>
                          <h6>
                            <strong>Office Hours</strong>
                          </h6>
                          <p>Mon-Fri 7am-5pm</p>
                        </Stack>
                      </Stack>
                    </Card.Body>
                  </Card>
                </Col>
                <Col xs={12} lg={6} className="contact-card-col">
                  <Card className="contact-card">
                    <Card.Body>
                      <h4>Get In Touch</h4>
                      <Stack
                        direction="column"
                        space={20}
                        sx={{ marginTop: "20px", marginBottom: "20px" }}
                      >
                        <Stack direction="column" space={10}>
                          <a href="mailto:cjshaughnessycrane@gmail.com">
                            cjshaughnessycrane@gmail.com
                          </a>
                          <a className="phone" href="tel:+1-781-315-5321">
                            781.315.5321
                          </a>
                          <a className="phone" href="tel:+1-508-857-2769">
                            508.857.2769
                          </a>
                        </Stack>

                        <Stack direction="row" space={5}>
                          <a
                            title="facebook page"
                            className="logo facebook-anchor"
                            href="https://www.facebook.com/Cjshaughnessycrane/"
                            style={{ fontSize: 0 }}
                          >
                            facebook
                          </a>
                          <a
                            title="instagram page"
                            className="logo instagram-anchor"
                            href="https://www.instagram.com/cjshaughnessycrane/"
                            style={{ fontSize: 0 }}
                          >
                            instagram
                          </a>
                          <a
                            title="linkedin page"
                            className="logo linkedin-anchor"
                            href="https://www.linkedin.com/company/cj-shaughnessy-crane-service/mycompany/?viewAsMember=true"
                            style={{ fontSize: 0 }}
                          >
                            linkedin
                          </a>
                        </Stack>
                      </Stack>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
              <Card className="contact-card">
                <Card.Body>
                  <QuoteForm />
                </Card.Body>
              </Card>
            </Stack>
          </Col>
        </Row>
      </Container>
    </Container>
  );
};

export { Contact };
